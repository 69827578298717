/**
 * =============
 * DEFAULT THEME
 * =============
 */
.writer {
  color: black;
  @apply text-sm;
}

.writer p {
  color: inherit;
}

.writer strong {
  font-weight: bolder;
  color: theme('colors.bright-blue');
}

/* Hyperlink */
.writer a {
  @apply px-[.2em] pt-[.2em] text-dark-blue border-dark-blue border-b-2
}

.writer a:hover {
  @apply bg-bright-blue;
}

.writer a:focus-visible {
  @apply bg-bright-blue outline-2 outline-dark-blue outline-offset-0;
}

.writer a:disabled {
  color: theme('colors.bright-gray');
}

/* Copy */
.writer p + p {
  margin-top: theme('spacing.5');
}

.writer p + h2,
.writer p + h3 {
  margin-top: theme('spacing.12');
}

.writer ul + h2,
.writer ol + h2 {
  margin-top: theme('spacing.12');
}

.writer h2,
.writer h3 {
  @apply font-black uppercase;
}

.writer h2 {
  margin-bottom: theme('spacing.8');
  @apply text-xl;
}
.writer h3 {
  @apply text-lg;
}

/* Lists */
.writer ul,
.writer ol {
  padding-left: 1.25em;
  margin-top: theme('spacing.5');
  margin-bottom: theme('spacing.5');
}

.writer ol {
  list-style: numeric;

}
.writer ul {
  list-style: disc;
}

.writer li {
  margin-top: theme('spacing.[2.5]');
  margin-bottom: theme('spacing.[2.5]');
}

.writer ul ul,
.writer ul ol,
.writer ol ol,
.writer ol ul {
  margin-top: theme('spacing.[2.5]');
  margin-bottom: theme('spacing.[2.5]');
}


/**
 * ================================
 * WHITE THEME FOR DARK BACKGROUNDS
 * ================================
 */
.writer-white {
  color: white;
}

/* Hyperlink */
.writer-white a {
  color: white;
  border-color: white;
}

.writer-white a:hover {
  background-color: theme('colors.bright-blue');
}

.writer-white a:focus {
  color: theme('colors.dark-blue');
  background-color: theme('colors.bright-blue');
  outline-color: white;
}

.writer-white a:disabled {
  color: theme('colors.bright-gray');
}

/**
 * CALM THEME
 */
.writer-calm h2,
.writer-calm h3 {
  @apply text-base font-black normal-case;
}

.writer-calm h2 {
  @apply text-dark-blue;
}

.writer-calm h3 {
  @apply text-black;
}
