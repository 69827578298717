@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '../../node_modules/@glidejs/glide/dist/css/glide.core.css';
@import './writer.css';

:root {
  --header-height: 5rem;
}

@media screen and (min-width: 1024px) {
  :root {
    --header-height: 7.5rem;
  }
}

@keyframes scroll-down {
  0%, 100% {
    transform: translateY(-0.5rem);
  }
  50% {
    transform: translateY(0);
  }
}

@layer base {
  ::selection {
    @apply bg-dark-blue text-white bg-none text-fill-none;
  }

  @font-face {
    font-family: 'HolcimCustomType';
    src: url('../fonts/Holcim-HeadlineLight.woff2') format('woff2'),
    url('../fonts/Holcim-HeadlineLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'HolcimCustomType';
    src: url('../fonts/Holcim-Regular.woff2') format('woff2'),
    url('../fonts/Holcim-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'HolcimCustomType';
    src: url('../fonts/Holcim-Italic.woff2') format('woff2'),
    url('../fonts/Holcim-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'HolcimCustomType';
    src: url('../fonts/Holcim-Bold.woff2') format('woff2'),
    url('../fonts/Holcim-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'HolcimCustomType';
    src: url('../fonts/Holcim-HeadlineBlack.woff2') format('woff2'),
    url('../fonts/Holcim-HeadlineBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
  }

  [data-glide="page-slider"] .glide__slide {
    height: auto;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    [data-glide="page-slider"] .glide__bullet:not(:nth-child(2n + 1)) {
      display: none;
    }
  }

  @media screen and (min-width: 1024px) {
    [data-glide="page-slider"] .glide__bullet:not(:nth-child(3n + 1)) {
      display: none;
    }
  }

  .glide__bullet--active {
    @apply bg-current;
  }

  a,
  button {
    @apply outline-dark-blue outline-offset-4;
  }

  [x-cloak] { display: none !important; }
}

@layer components {
  .objectives-list {
    li {
      padding-top: theme('spacing.4');
    }
    li:not(:last-child) {
      padding-bottom: theme('spacing.4');
      border-bottom: 1px solid theme('colors.bright-gray')
    }
  }

  .timeline {
    @apply w-full relative;
  }

  .timeline__timeline {
    @apply hidden;
  }

  .timeline__year {
    @apply font-bold text-white py-4 relative;
  }

  .timeline__button {
    @apply block w-full pt-6 pb-6 px-1 border-2 transition-all hover:opacity-95 hyphens-auto text-white border-transparent min-h-[10rem] mb-8;
    background-color: var(--phase-color);
  }

  .timeline__button_timerange {
    @apply block;
  }

  .timeline__button_chevron {
    @apply mt-6 block transition-all
  }

  .timeline__button--active {
    @apply text-dark-blue border-dark-blue bg-white;
  }

  .timeline__button:hover .timeline__button_chevron {
    @apply translate-y-1;
  }

  .timeline__tab {
    @apply p-8 bg-white border-2 text-lg mb-4 relative;
  }

  .timeline__tabs {
    @apply hidden;
  }

  @media screen and (min-width: theme('screens.lg')) {
    .timeline__timeline {
      @apply flex bg-c2b-gradient w-full px-8 mb-2 justify-between clip-path-arrow text-center items-center;
    }

    .timeline__year {
      flex-basis: 15.384615384615384615384615384615%;
    }

    .timeline__year:first-child {
      flex-basis: calc(15.384615384615384615384615384615% - 32px);
    }

    .timeline__year:last-child {
      @apply pr-8;
      flex-basis: calc(7.692307692307692307692307692308% - 32px);
    }

    .timeline__button_wrapper {
      @apply flex justify-start items-stretch gap-1 mb-8;
    }

    .timeline__button {
      @apply min-h-0;
      flex-basis: var(--flex-basis);
    }

    .timeline__button--active {
      @apply h-fit;
    }

    .timeline_button:first-of-type {
      @apply pl-8;
    }

    .timeline_button:last-of-type {
      @apply pr-8;
    }

    .timeline__button--active .timeline__button_chevron {
      @apply hidden;
    }

    .timeline__button_timerange {
      @apply hidden;
    }

    .timeline__button_inline_tab {
      @apply hidden;
    }

    .timeline__tabs {
      @apply block;
    }
  }
}

@layer utilities {
  .text-fill-transparent {
    -webkit-text-fill-color: transparent;
  }

  .text-fill-none {
    -webkit-text-fill-color: currentColor;
  }

  .fancy-headline {
    @apply py-2 text-fill-transparent bg-clip-text bg-c2b-gradient font-black uppercase;
  }

  .grid-cols-claim {
    --claim-column-width: 100%;

    grid-template-columns:
        minmax(0, 1fr)
        minmax(var(--claim-column-width), 1fr)
        minmax(0, 1fr)
    ;
  }

  @media screen and (min-width: 640px) {
    .grid-cols-claim {
      --claim-column-width: 100%;
    }
  }
  @media screen and (min-width: 768px) {
    .grid-cols-claim {
      --claim-column-width: 100%;
    }
  }
  @media screen and (min-width: 1024px) {
    .grid-cols-claim {
      --claim-column-width: 1000px
    }
  }
  @media screen and (min-width: 1280px) {
    .grid-cols-claim {
      --claim-column-width: 1280px;
    }
  }

  .fancy-bar:after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 8px;
    @apply bg-c2b-gradient;
  }

  .item-spacer-before::before {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    @apply mt-5 mb-5 bg-bright-gray;
  }

  .item-spacer-after::after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    @apply mt-5 mb-5 bg-bright-gray;
  }

  .h-header {
    height: var(--header-height);
  }

  .h-screen-without-header {
    height: calc(100vh - var(--header-height));
  }

  .max-w-half {
    max-width: 50%;
  }

  .clip-path-arrow {
    clip-path: polygon(0 0, 0 100%, calc(100% - 1.5rem) 100%, 100% 50%, calc(100% - 1.5rem) 0);
  }

  .animate-scroll-down {
    animation: scroll-down 2s infinite ease-in-out;
  }
}
